<template>
  <section class="fiestas bg-gold pt-topmenu xl:pt-0">
    <div class="hidden xl:block">
      <divider />

      <!-- Header -->
      <div class="mx-xlpadding xxl:mx-xxlpadding flex justify-end mb-10">
        <span
          class="text-sm circled"
          style="width: 66px; height: 33px; margin-top: 10px"
          >Fiestas</span
        >
      </div>
    </div>
    <div
      class="flex flex-col md:flex-row md:justify-between md:items-end mb-xlpadding mx-padding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:mx-xxlpadding mt-5 md:mt-10"
    >
      <div class="md:w-half">
        <h1
          class="font-title italic text-bigtitle lg:text-lgtitle xl:text-xltitle leading-bigtitle lg:leading-lgtitle xl:leading-xltitle md:max-w-block"
        >
          Benditas <br />
          Fiestas.
        </h1>
      </div>
      <div
        class="md:w-half md:pl-8 mt-5 md:mt-0 text-h2 lg:text-lgh2 leading-h2 lg:leading-lgh2 font-medium"
      >
        Vamos a hacer de este día, un día especial, de los que sólo se viven una
        vez.
      </div>
    </div>

    <!-- Cover image -->
    <img
      src="../assets/pictures/cover-fiestas.jpg"
      :srcset="`
        ${require(`../assets/pictures/cover-fiestas${
          $mq === 'xs' ? '-mobile' : ''
        }.jpg`)},
        ${require(`../assets/pictures/cover-fiestas${
          $mq === 'xs' ? '-mobile' : ''
        }@2x.jpg`)} 2x,
        ${require(`../assets/pictures/cover-fiestas${
          $mq === 'xs' ? '-mobile' : ''
        }@3x.jpg`)} 3x
        `"
      style="width: 100%"
    />

    <div
      class="flex flex-col md:flex-row md:justify-between m-padding md:m-mdpadding lg:m-lgpadding xl:m-xlpadding xxl:mx-xxlpadding my-xlpadding"
    >
      <div
        class="text-h2 lg:text-lgh2 leading-h2 lg:leading-lgh2 font-medium md:w-half"
      >
        <div class="md:max-w-block">
          Hacemos que las cosas pasen. Si quieres organizar algo diferente
          Bendita Jarana es para ti.
        </div>
      </div>
      <div class="lg:flex lg:flex-wrap md:w-half mt-padding md:mt-0 md:pl-8">
        <div class="flex-1 lg:pr-4">
          <p>
            Llevamos la creatividad en nuestro ADN, por lo que las ideas más
            locas son las que más nos gustan y no, no
          </p>
        </div>
        <div class="flex-1 lg:pl-4 mt-4 lg:mt-0">
          <p>
            pararemos hasta conseguir que vivas la mayor fiesta de tu vida. Pide
            lo que quieras, lo vamos a hacer realidad.
          </p>
        </div>
      </div>
    </div>

    <projects project-type="fiesta" />

    <!-- Contact -->
    <divider />
    <contact-section />

    <!-- Footer -->
    <JFooter />
    <divider />
  </section>
</template>

<script>
import Divider from "../components/Divider";
import ContactSection from "../components/ContactSection";
import JFooter from "../components/Footer";
import Projects from "../components/Projects.vue";

export default {
  name: "Fiestas",
  components: {
    Divider,
    ContactSection,
    JFooter,
    Projects,
  },
};
</script>

<style lang="scss" scoped></style>
